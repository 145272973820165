import React from "react";

// components
import LargeIcons from "../Icons/Large";
import AnchorWrapper from "../TextMedia/AnchorWrapper";

interface Props {
  data?: any | undefined;
  removePadding?: boolean | null;
}

const TextIcon: React.FC<Props> = (props) => {
  const { data, removePadding } = props;

  return (
    <div
      className={`c-texticon uk-padding-remove-horizontal ${
        removePadding ? "uk-padding-small" : "uk-padding-large"
      }`}
    >
      <div className="uk-text-left uk-grid-large" data-uk-grid="">
        {data &&
          data.map((item: any, index: number) => {
            return (
              <div
                key={`content_item_${index}`}
                className="uk-width-1-1 uk-width-1-2@s"
              >
                <AnchorWrapper
                  condition={item.buttonUrl ? true : false}
                  wrapper={(children) => (
                    <a
                      className="uk-link-reset"
                      href={item.buttonUrl}
                      target={item.buttonTarget}
                      rel={item.buttonRel}
                    >
                      {children}
                    </a>
                  )}
                >
                  <div className="uk-flex-top uk-flex uk-flex-middle">
                    <div className="c-texticon__icon">
                      <LargeIcons
                        crossed={item.crossed}
                        iconUrl={item.iconUrl}
                        iconTitle={item.iconTitle}
                      />
                    </div>
                    <div className="c-texticon__content uk-width-expand">
                      {item.heading && (
                        <h2 className="c-texticon__heading uk-h5 uk-h4@m uk-h3@l uk-h2@xl">
                          {item.heading}
                        </h2>
                      )}
                      {item.subHeading && <strong>{item.subHeading}</strong>}
                      {item.content && (
                        <div
                          className="c-texticon__text"
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        />
                      )}
                    </div>
                  </div>
                </AnchorWrapper>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TextIcon;
