import React from "react";
import { graphql } from "gatsby";

import camelCase from "lodash/camelCase";
import toLower from "lodash/toLower";

// components
import Seo from "../components/Layouts/Seo";
import Layout from "../components/Layouts/Layouts";
import LeadIn from "../components/Content/LeadIn/Index";
import RelatedProducts from "../components/Content/Product/Related";
import FeatureCaseStudy from "../components/Content/FeatureArticle/Dynamic";

// assets
import iconSearch from "../assets/icons/icon-search.svg";
import Icons from "../components/Content/Icons/Index";

// helpers
import { BrandAttributes } from "../helpers/hooks/BrandQuery";
import { UseBlogData } from "../helpers/hooks/BlogQuery";
import TextIcon from "../components/Content/TextIcon/Index";

interface Props {
  readonly data: PageQueryData;
}

const ProductTemplate: React.FC<Props> = ({ data }) => {
  const { product, relatedProducts } = data;
  const featuredBlog = UseBlogData();
  const brandStripped =
    product?.fields?.brandSlug?.replace(/[^\w\s]/gi, "") || "";

  const productImages = product.assets.filter(
    (asset) => asset.assetType === "Image"
  );

  const removeAttributesFromFields = (
    data: any,
    attrToRemove: Array<string>
  ) => {
    const filtered = data.filter(
      (entry: any) => attrToRemove.indexOf(entry.name) < 0
    );
    return filtered.map((entry: any) => {
      if (!entry.attributes) return entry;
      return {
        ...entry,
        attributes: removeAttributesFromFields(entry.attributes, attrToRemove),
      };
    });
  };

  const groupsToRemove = [
    // Parent Groups
    "Description",
    "Summary",
    "Features",
    "Line Drawing Dimensions",
    "Product Matching Rules",
    "General Information",
    // Inner Groups
    "Pressure Rating",
    "Product Type",
    "Jointing System",
    "Information",
    "Angle",
    "Product Type (Reporting Only)",
    "Valve Application",
    "Connection Type",
    "Standards",
    "Maximum Continuous Working Pressure",
    "Minimum Continuous Working Temperature",
    "Size Options",
    "Weight",
    "Application",
    "Connection",
    "O-Ring Temperature Range",
    "Pressure Rating",
    "Product Application",
  ];
  const productSpecifications = removeAttributesFromFields(
    product.attributes,
    groupsToRemove
  );

  const lineDrawingGroup = product.attributes.find(
    (group) => group.name === "Line Drawing Dimensions"
  );

  // line images are sometimes .ai files
  const lineDrawingImage = product.assets.find(
    (asset) => asset.assetType === "Technical_Drawing"
  );
  let lineDrawaingUrl = lineDrawingImage?.originalUrl;
  if (!["png", "jpg"].includes(toLower(lineDrawingImage?.format))) {
    const thumbnails = lineDrawingImage?.thumbnails.sort(
      (a, b) => b.width - a.width
    );
    lineDrawaingUrl = thumbnails ? thumbnails[0].absoluteUrl : lineDrawaingUrl;
  }

  const downloads = product.assets.filter((asset) =>
    ["Document", "Model_File", "Technical_Drawing"].includes(asset.assetType)
  );
  let downloadData: any[] = [];
  downloads.forEach((download) => {
    let icon;
    const file: any = download.originalUrl;
    if (file.includes("rfa")) {
      icon = "/icons/icon-cube-solid.svg";
    } else if (file.includes("ai")) {
      icon = "/icons/icon-drawing.svg";
    } else {
      icon = "/icons/icon-document.svg";
    }

    const downloadObj = {
      subHeading: download.title,
      crossed: false,
      iconUrl: icon,
      iconTitle: "Placeholder",
      buttonUrl: download.originalUrl,
      buttonTarget: "_blank",
      buttonRel: "noreferrer",
    };
    downloadData = [...downloadData, downloadObj];
  });

  let attributes: Attribute[] = [];
  product.attributes.forEach((group) => {
    group.attributes.forEach((attribute) => {
      attributes = [...attributes, attribute];
    });
  });

  return (
    <Layout>
      <Seo
        title={`${product.fields.brandTitle} - ${product.fields.productTitle}`}
        description={product.fields.brandSummary}
      />
      <section id="pagetop" className="uk-section">
        <div className="uk-container">
          <ul className="uk-breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/products">Products</a>
            </li>
            <li>
              <span>
                {product.subBrandTitle} {product.fields.productTitleDisplay}
              </span>
            </li>
          </ul>

          <div className="uk-grid-match uk-margin-medium" data-uk-grid="">
            <div className="uk-width-1-3@m">
              <div data-uk-scrollspy="target: > *; cls: uk-animation-slide-bottom-small; delay: 100">
                <h1>
                  {BrandAttributes && (
                    <div
                      className="uk-margin-top"
                      style={{
                        width: BrandAttributes[product.fields.brandSlug]?.width,
                      }}
                    >
                      <img
                        alt={BrandAttributes[product.fields.brandSlug]?.title}
                        className="uk-width-1-1"
                        src={BrandAttributes[product.fields.brandSlug]?.icon}
                      />
                    </div>
                  )}
                  <div className="uk-h5 uk-h4@m uk-h3@l uk-h2@xl uk-margin-remove-top">
                    {product.fields.productTitleDisplay}
                  </div>
                </h1>
                <p>{product.fields.brandSummary}</p>
                {product.fields.applications && (
                  <div className="uk-flex" data-uk-margin="">
                    {product.fields.applications.map((application) => (
                      <Icons
                        key={application}
                        iconType={application}
                        iconSize="small"
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="uk-width-2-3@m uk-flex-top uk-flex-first uk-flex-first@s">
              {productImages && (
                <div data-uk-slideshow="animation: fade; autoplay: true; autoplay: true; autoplay-interval: 6000; ratio: 4:3">
                  {/* Slide image */}
                  <div className="uk-position-relative uk-visible-toggle uk-light">
                    <ul className="uk-slideshow-items">
                      {productImages.map((image: any, index: number) => {
                        return (
                          <li key={`slider_item_${index}`}>
                            <img
                              width="785"
                              height="588"
                              src={image.originalUrl}
                              alt={image.title}
                              data-uk-cover=""
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {/* Slide thumbs */}
                  {productImages.length > 1 && (
                    <ul className="uk-flex uk-list uk-list-collapse">
                      {productImages.map((image: any, index: number) => {
                        return (
                          <li
                            key={`slider_thumb_${index}`}
                            className="uk-margin-right"
                            data-uk-slideshow-item={index}
                          >
                            <a className="uk-display-inline-block">
                              <img
                                width="69px"
                                height="51px"
                                src={image.originalUrl}
                                alt={image.title}
                              />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="c-products-spec uk-margin-large">
            <h2 className="uk-h5 uk-h4@m uk-h3@l uk-h2@xl">
              Product Specifications
            </h2>
            <hr className="uk-divider-small" />
            <div
              className="uk-flex-bottom uk-child-width-1-2@m uk-grid-match uk-grid-large uk-margin"
              data-uk-grid=""
            >
              <div>
                <ul className="uk-list uk-list-large uk-list-divider">
                  <li>
                    <strong className="c-text-xsmall uk-display-block">
                      Product Code
                    </strong>
                    <span>{product.productNumber || "-"}</span>
                  </li>
                  {productSpecifications.map((group: any) => (
                    <li key={`group_${camelCase(group.name)}`}>
                      {group.attributes.length === 1 && (
                        <>
                          <strong className="c-text-xsmall uk-display-block">
                            {group.attributes[0].name}
                          </strong>
                          <span>
                            {group.attributes[0].value}{" "}
                            {group.attributes[0].uom &&
                              ` ${group.attributes[0].uom}`}
                          </span>
                        </>
                      )}
                      {group.attributes.length > 1 && (
                        <>
                          <strong className="c-text-xsmall uk-display-block">
                            {group.name}
                          </strong>
                          <ul className="uk-list">
                            {group.attributes.map((attribute: any) => (
                              <li
                                key={`attribute_${camelCase(attribute.name)}`}
                              >
                                <strong className="c-text-xsmall uk-display-block">
                                  {attribute.name}
                                </strong>
                                <span>
                                  {attribute.value}
                                  {attribute.uom && ` ${attribute.uom}`}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                {lineDrawingImage && (
                  <>
                    <img
                      src={lineDrawaingUrl}
                      alt={`Line Drawing - ${lineDrawingImage.title}`}
                    />
                    <ul className="uk-list uk-list-large uk-list-divider uk-margin-remove">
                      {lineDrawingGroup?.attributes.map((attribute) => (
                        <li key={`attribute_${camelCase(attribute.name)}`}>
                          <strong className="c-text-xsmall uk-display-block">
                            {attribute.name}
                          </strong>
                          <span>{attribute.value || "-"}</span>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
            <div className="uk-margin-large">
              <a
                href={product.fields.brandSlug.replace("maxi-pro", "maxipro")}
                className="uk-button uk-button-primary"
              >
                Discover Features
              </a>
            </div>
          </div>
          <hr />
          {downloadData.length ? (
            <>
              <div className="uk-margin-large">
                <h2 className="uk-h5 uk-h4@m uk-h3@l uk-h2@xl">Downloads</h2>
                <hr className="uk-divider-small" />
                <div className="uk-text-bold">
                  <TextIcon removePadding={true} data={downloadData} />
                </div>
              </div>
              <hr />
            </>
          ) : (
            ""
          )}
          <RelatedProducts data={relatedProducts} />
          <FeatureCaseStudy data={featuredBlog[brandStripped]} />
        </div>
      </section>
      <LeadIn
        section="primary"
        brandMediaUrl={iconSearch}
        brandMediaTitle="Download"
        brandMediaWidth="69px"
        summary="Need help finding the right system for the job?"
        paddingSize="small"
        buttonValue="Launch Product Selector"
        buttonUrl="#js-product-selector-app"
        buttonModal={true}
      />
    </Layout>
  );
};

interface Thumbnail {
  absoluteUrl: string;
  height: number;
  width: number;
}

interface Asset {
  assetType: string;
  format: string;
  originalUrl: string;
  thumbnails: Thumbnail[];
  title: string;
}

interface Attribute {
  name: string;
  uom: string | null;
  value: string;
}

interface AttributeGroup {
  attributes: Attribute[];
  name: string;
}

interface PageQueryData {
  product: {
    title: string;
    fields: {
      applications: ["gas" | "water" | "high-temperature" | "refrigerant"];
      brandTitle: string;
      brandSummary: string;
      brandSlug: string;
      productTitleLong: string;
      productTitle: string;
      productTitleDisplay: string;
      fittingSize: string;
    };
    assets: Asset[];
    attributes: AttributeGroup[];
    productNumber: string;
    subBrandTitle: string;
  };
  relatedProducts: {
    edges: {
      assets: Asset[];
      fields: {
        title: string;
        applications: ["gas" | "water" | "high-temperature" | "refrigerant"];
        brandTitle: string;
        brandSlug: string;
        slug: string;
      };
    };
  };
}

export const pageQuery = graphql`
  query ProductBySlug(
    $limit: Int!
    $slug: String!
    $brand: String!
    $application: String!
    $fittingType: String!
  ) {
    product(fields: { productSlug: { eq: $slug } }) {
      attributes {
        name
        attributes {
          name
          uom
          value
        }
      }
      assets {
        assetType
        format
        originalUrl
        thumbnails {
          absoluteUrl
          height
          width
        }
        title
      }
      fields {
        applications
        brandSlug
        brandTitle
        brandSummary
        productTitleLong
        productTitle
        productTitleDisplay
        fittingSize
      }
      productNumber
      subBrandTitle
    }
    relatedProducts: allProduct(
      limit: $limit
      filter: {
        fields: {
          brandSlug: { eq: $brand }
          applications: { eq: $application }
          fittingType: { eq: $fittingType }
        }
      }
      sort: { fields: fields___fittingSize, order: ASC }
    ) {
      edges {
        node {
          assets {
            originalUrl
            subAssetType
            thumbnails {
              absoluteUrl
            }
            title
          }
          fields {
            applications
            brandSlug
            brandTitle
            productSlug
            productTitle
            productTitleDisplay
          }
          id
        }
      }
    }
  }
`;

export default ProductTemplate;
